import { render, staticRenderFns } from "./PageAuthor.vue?vue&type=template&id=5d0bace0&scoped=true"
import script from "./PageAuthor.vue?vue&type=script&lang=js"
export * from "./PageAuthor.vue?vue&type=script&lang=js"
import style0 from "./PageAuthor.vue?vue&type=style&index=0&id=5d0bace0&prod&lang=css"
import style1 from "./PageAuthor.vue?vue&type=style&index=1&id=5d0bace0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d0bace0",
  null
  
)

export default component.exports